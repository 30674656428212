// in app.js
import React from "react"
import { Admin, Resource } from "react-admin"
import crudProvider from "@fusionworks/ra-data-nest-crud"
import { UserIcon, UserList, UserShow } from "./resources/user"
import { useConfig } from "./config"
import { createAuthProvider, httpClient } from "./lib/auth"
import { CompanyList, CompanyShow, CompanyIcon, CompanyEdit } from "./resources/company"
import { SerieList, SerieShow, SerieIcon } from "./resources/serie"
import { SourceList, SourceShow, SourceIcon } from "./resources/source"
import { TaskList, TaskShow, TaskIcon } from "./resources/task"

export default function App() {
  const { API_URL } = useConfig()
  const authProvider = createAuthProvider(API_URL)
  const dataProvider = crudProvider(API_URL + "/v1/admin", httpClient)

  return (
    <Admin
      authProvider={authProvider}
      dataProvider={async (action, resource, options) => {
        const res = await dataProvider(action, resource, options)
        // fix data provider result
        if (action === "GET_MANY" && res.data && res.data.data && Array.isArray(res.data.data)) {
          return res.data
        }
        return res
      }}
    >
      <Resource name="user" list={UserList} show={UserShow} icon={UserIcon} />
      <Resource name="company" list={CompanyList} show={CompanyShow} edit={CompanyEdit} icon={CompanyIcon} />
      <Resource name="serie" list={SerieList} show={SerieShow} icon={SerieIcon} />
      <Resource name="source" list={SourceList} show={SourceShow} icon={SourceIcon} />
      <Resource
        name="forecast-task"
        options={{ label: "Forecast Tasks" }}
        list={TaskList}
        show={TaskShow}
        icon={TaskIcon}
      />
      <Resource
        name="source-cluster-task"
        options={{ label: "Source Cluster Tasks" }}
        list={TaskList}
        show={TaskShow}
        icon={TaskIcon}
      />
      <Resource
        name="serie-sync-task"
        options={{ label: "Serie Sync Tasks" }}
        list={TaskList}
        show={TaskShow}
        icon={TaskIcon}
      />
      <Resource
        name="context-impact-task"
        options={{ label: "Context Impact Tasks" }}
        list={TaskList}
        show={TaskShow}
        icon={TaskIcon}
      />
    </Admin>
  )
}
