// in posts.js
import React, { useState } from "react"
import * as Cookies from "js-cookie"

import {
  List,
  Datagrid,
  DateField,
  TextField,
  useNotify,
  Button,
  ShowButton,
  Show,
  SimpleShowLayout,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Filter,
} from "react-admin"

import VerifiedUser from "@material-ui/icons/VerifiedUser"
import { useConfig } from "../config"
import { httpClient } from "../lib/auth"
export const UserIcon = VerifiedUser

const UserBulkActionButtons = () => <></>

export const ImpersonateButton = ({ record }: any) => {
  const { API_URL, FRONT_URL, IMPERSONATE_COOKIE_DOMAIN, IMPERSONATE_COOKIE_NAME } = useConfig()
  const notify = useNotify()
  const [loading, setLoading] = useState<boolean>(false)
  const impersonate = async () => {
    setLoading(true)
    try {
      const response = await httpClient(
        API_URL + "/v1/admin/user/impersonate?email=" + encodeURIComponent(record.email),
        {
          method: "POST",
        },
      )
      const jwt = response.json.jwt
      Cookies.set(IMPERSONATE_COOKIE_NAME, jwt, { domain: IMPERSONATE_COOKIE_DOMAIN })

      notify("Impersonation success")
      setTimeout(() => window.location.replace(FRONT_URL), 200)
    } catch (error) {
      notify(`Impersonation error: ${error.message}`, "warning")
    } finally {
      setLoading(false)
    }
  }

  return <Button label="Impersonate" onClick={impersonate} disabled={loading} />
}

const UserFilter = props => (
  <Filter {...props}>
    <ReferenceInput label="Company" source="companyId" reference="company">
      <SelectInput optionText={record => `${record.name} (id: ${record.id})`} />
    </ReferenceInput>
  </Filter>
)

export const UserList = props => (
  <List {...props} bulkActionButtons={<UserBulkActionButtons />} filters={<UserFilter />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="username" />
      <TextField source="email" />
      <ReferenceField label="Company" source="companyId" reference="company" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdDate" showTime={true} />
      <ShowButton />
      <ImpersonateButton />
    </Datagrid>
  </List>
)

export const UserShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="username" />
      <TextField source="email" />
      <DateField source="createdDate" showTime={true} />
      <DateField source="updatedDate" showTime={true} />
      <DateField source="lastConnectionDate" showTime={true} />
      <ReferenceField label="Company" source="companyId" reference="company" link="show">
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
)
