import React from "react"
import {
  List,
  Datagrid,
  DateField,
  TextField,
  ShowButton,
  Show,
  SimpleShowLayout,
  ReferenceField,
  BooleanField,
  NumberField,
  ReferenceInput,
  SelectInput,
  Filter,
} from "react-admin"
import BarChartIcon from "@material-ui/icons/BarChart"
export const SourceIcon = BarChartIcon

const SourceBulkActionButtons = () => <></>
const JsonPrettyField = ({ source, record = {} }) => (
  <span>
    <pre>{JSON.stringify(record[source], null, 2)}</pre>
  </span>
)

const SourceFilter = props => (
  <Filter {...props}>
    <ReferenceInput label="Company" source="companyId" reference="company">
      <SelectInput optionValue="id" optionText={record => `${record.name} (id: ${record.id})`} />
    </ReferenceInput>
  </Filter>
)

export const SourceList = props => (
  <List {...props} bulkActionButtons={<SourceBulkActionButtons />} filters={<SourceFilter />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="address" />
      <BooleanField source="isPublic" />
      <ReferenceField label="Company" source="companyId" reference="company" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Serie" source="serieId" reference="serie" link="show">
        <TextField source="id" />
      </ReferenceField>
      <DateField source="createdDate" showTime={true} />
      <ShowButton />
    </Datagrid>
  </List>
)

export const SourceShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <NumberField source="id" />
      <TextField source="name" />
      <NumberField source="groupId" />
      <TextField source="groupInertia" />
      <NumberField source="projectionX" />
      <NumberField source="projectionY" />
      <NumberField source="referenceYear" />
      <BooleanField source="hasPrevision" />
      <NumberField source="latitude" />
      <NumberField source="longitude" />
      <TextField source="address" />
      <BooleanField source="previsionEnabled" />
      <NumberField source="alertMinCriticity" />
      <NumberField source="alertMaxCriticity" />
      <BooleanField source="hasCustomModels" />
      <BooleanField source="syncMeteo" />
      <BooleanField source="isPublic" />
      <JsonPrettyField source="metadata" />
      <ReferenceField label="Company" source="companyId" reference="company" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Serie" source="serieId" reference="serie" link="show">
        <TextField source="id" />
      </ReferenceField>
      <DateField source="createdDate" showTime={true} />
      <DateField source="updatedDate" showTime={true} />
    </SimpleShowLayout>
  </Show>
)
