import React from "react"
import {
  List,
  Datagrid,
  DateField,
  TextField,
  ShowButton,
  Show,
  SimpleShowLayout,
  ReferenceField,
  SelectInput,
  Filter,
  ReferenceInput,
} from "react-admin"
import SettingsIcon from "@material-ui/icons/Settings"
export const TaskIcon = SettingsIcon

const TaskBulkActionButtons = () => <></>
const JsonPrettyField = ({ record = {} }) => (
  <span>
    <pre>{JSON.stringify(record, null, 2)}</pre>
  </span>
)

const TaskFilter = props => (
  <Filter {...props}>
    <SelectInput
      source="status"
      choices={[
        { id: "waiting", name: "Waiting" },
        { id: "active", name: "Active" },
        { id: "completed", name: "Completed" },
        { id: "failed", name: "Failed" },
      ]}
    />
    <ReferenceInput label="Company" source="companyId" reference="company">
      <SelectInput optionText={record => `${record.name} (id: ${record.id})`} />
    </ReferenceInput>
  </Filter>
)

export const TaskList = props => (
  <List {...props} bulkActionButtons={<TaskBulkActionButtons />} filters={<TaskFilter />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="status" />
      <TextField source="title" />
      <ReferenceField label="Company" source="companyId" reference="company" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdDate" showTime={true} />
      <ShowButton />
    </Datagrid>
  </List>
)

export const TaskShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="status" />
      <TextField source="error" />
      <TextField source="title" />
      <JsonPrettyField />
      <ReferenceField label="Company" source="companyId" reference="company" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdDate" showTime={true} />
      <DateField source="updatedDate" showTime={true} />
    </SimpleShowLayout>
  </Show>
)
