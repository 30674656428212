import React from "react"

export type AppTypesType = "TIMELIGHT" | "SECURE_ENERGY"

// the current app route
export interface ConfigContextState {
  API_URL: string
  FRONT_URL: string
  IMPERSONATE_COOKIE_NAME: string
  IMPERSONATE_COOKIE_DOMAIN: string
}

// parse config from env
export const defaultConfig: ConfigContextState = {
  // CRA env need the REACT_APP_ prefix
  // https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables
  API_URL: process.env.REACT_APP_API_URL || "http://localhost:3000",
  FRONT_URL: process.env.REACT_APP_FRONT_URL || "http://localhost:5000",
  IMPERSONATE_COOKIE_NAME: process.env.REACT_APP_IMPERSONATE_COOKIE_NAME || "jwt",
  IMPERSONATE_COOKIE_DOMAIN: process.env.REACT_APP_IMPERSONATE_COOKIE_DOMAIN || "localhost",
}

export interface ConfigFile {
  API_URL: string
  FRONT_URL: string
  IMPERSONATE_COOKIE_NAME: string
  IMPERSONATE_COOKIE_DOMAIN: string
}
export function parseConfigFile(content: Partial<ConfigFile>): ConfigContextState {
  return {
    API_URL: content.API_URL || defaultConfig.API_URL,
    FRONT_URL: content.FRONT_URL || defaultConfig.FRONT_URL,
    IMPERSONATE_COOKIE_NAME: content.IMPERSONATE_COOKIE_NAME || defaultConfig.IMPERSONATE_COOKIE_NAME,
    IMPERSONATE_COOKIE_DOMAIN: content.IMPERSONATE_COOKIE_DOMAIN || defaultConfig.IMPERSONATE_COOKIE_DOMAIN,
  }
}

const ConfigContext = React.createContext<ConfigContextState>(defaultConfig)

export const ConfigContextProvider = ConfigContext.Provider
export const ConfigContextConsumer = ConfigContext.Consumer

export function useConfig(): ConfigContextState {
  return React.useContext(ConfigContext)
}
