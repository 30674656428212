import { fetchUtils } from "react-admin"

const TOKEN_KEY = "admin-auth-token"

// in src/authProvider.js
export function createAuthProvider(apiUrl: string) {
  return {
    login: ({ username, password }) => {
      const request = new Request(apiUrl + "/v1/user/login", {
        method: "POST",
        body: JSON.stringify({ username, password }),
        headers: new Headers({ "Content-Type": "application/json" }),
      })
      return fetch(request)
        .then(response => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText)
          }
          return response.json()
        })
        .then(({ jwt }) => {
          localStorage.setItem(TOKEN_KEY, jwt)
        })
    },

    logout: () => {
      localStorage.removeItem(TOKEN_KEY)
      return Promise.resolve()
    },

    checkError: error => {
      const status = error.status
      if (status === 401 || status === 403) {
        localStorage.removeItem(TOKEN_KEY)
        return Promise.reject()
      }
      return Promise.resolve()
    },

    checkAuth: () => (localStorage.getItem(TOKEN_KEY) ? Promise.resolve() : Promise.reject()),
    getPermissions: () => Promise.resolve(),
  }
}

export const httpClient = (url, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" })
  }
  const token = localStorage.getItem(TOKEN_KEY)
  options.headers.set("Authorization", `Bearer ${token}`)
  return fetchUtils.fetchJson(url, options)
}
