import React from "react"
import {
  List,
  Datagrid,
  DateField,
  TextField,
  ShowButton,
  EditButton,
  Show,
  SimpleShowLayout,
  SelectInput,
  Edit,
  SimpleForm,
  TextInput,
  required,
  minLength,
  maxLength,
  ReferenceManyField,
} from "react-admin"
import BusinessIcon from "@material-ui/icons/Business"
import { ImpersonateButton } from "./user"
export const CompanyIcon = BusinessIcon

const CompanyBulkActionButtons = () => <></>

export const CompanyList = props => (
  <List {...props} bulkActionButtons={<CompanyBulkActionButtons />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="plan" />
      <DateField source="createdDate" showTime={true} />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)

export const CompanyShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="plan" />
      <DateField source="createdDate" showTime={true} />
      <DateField source="updatedDate" showTime={true} />

      <ReferenceManyField reference="user" target="companyId" addLabel={false}>
        <Datagrid>
          <TextField source="id" />
          <TextField source="username" />
          <TextField source="email" />
          <ImpersonateButton />
          <ShowButton />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
)

const validateCompanyName = [required(), minLength(2), maxLength(50)]
export const CompanyEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput source="name" validate={validateCompanyName} />
      <SelectInput
        source="plan"
        label="Company Plan"
        choices={[
          { id: "free", name: "Free (5 sources)" },
          { id: "client_small", name: "Small (50 sources)" },
          { id: "client_medium", name: "Medium (500 sources)" },
          { id: "client_large", name: "Large (5000 sources)" },
          { id: "unlimited", name: "Unlimited (or max from instance config if MAX_SOURCE_COUNT is set)" },
        ]}
      />
    </SimpleForm>
  </Edit>
)
