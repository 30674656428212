import React from "react"
import {
  List,
  Datagrid,
  DateField,
  TextField,
  ShowButton,
  Show,
  SimpleShowLayout,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Filter,
} from "react-admin"
import ShowChartIcon from "@material-ui/icons/ShowChart"
export const SerieIcon = ShowChartIcon

const SerieBulkActionButtons = () => <></>

const StringArrayField = ({ source, record = {} }) => <span>{record[source].join(", ")}</span>
const SerieTypeField = ({ source, record = {} }) => <span>{(record[source] || {}).type}</span>
const JsonPrettyField = ({ source, record = {} }) => (
  <span>
    <pre>{JSON.stringify(record[source], null, 2)}</pre>
  </span>
)

const SerieFilter = props => (
  <Filter {...props}>
    <ReferenceInput label="Company" source="companyId" reference="company">
      <SelectInput optionText={record => `${record.name} (id: ${record.id})`} />
    </ReferenceInput>
  </Filter>
)

export const SerieList = props => (
  <List {...props} bulkActionButtons={<SerieBulkActionButtons />} filters={<SerieFilter />}>
    <Datagrid>
      <TextField source="id" />
      <StringArrayField source="blockMetrics" />
      <TextField source="blockTimeStep" />
      <TextField source="blockTimeSpan" />
      <SerieTypeField source="tags" />
      <ReferenceField label="Company" source="companyId" reference="company" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdDate" showTime={true} />
      <ShowButton />
    </Datagrid>
  </List>
)

export const SerieShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <StringArrayField source="blockMetrics" />
      <TextField source="blockTimeStep" />
      <TextField source="blockTimeSpan" />
      <JsonPrettyField source="tags" />
      <ReferenceField label="Company" source="companyId" reference="company" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdDate" showTime={true} />
      <DateField source="updatedDate" showTime={true} />
    </SimpleShowLayout>
  </Show>
)
