import { ConfigContextProvider } from "config"
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { ConfigFile, parseConfigFile } from "./config"
import "./index.css"
import * as serviceWorker from "./serviceWorker"

const publicUrl = (process.env.PUBLIC_URL || window.location.origin).replace(/\/$/, "")

async function init() {
  // fetch the application config
  let config: Partial<ConfigFile> = {}
  try {
    const res = await fetch(publicUrl + "/config.json")
    if (!res.ok) {
      throw new Error("HTTP error " + res.status)
    }
    config = await res.json()
  } catch (e) {
    // tslint-disable-next-line
    console.error("Could net fetch config, using defaults")
  }

  // add config as a context and render the app
  ReactDOM.render(
    <ConfigContextProvider value={parseConfigFile(config)}>
      <App />
    </ConfigContextProvider>,
    document.getElementById("root"),
  )
}

// tslint:disable-next-line:no-floating-promises
init()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
